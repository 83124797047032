import { GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import './App.css';
import links from './links';


const App = () => {
  const [user, setUser] = useState();

  const handleSignIn = async response => {
    const userObject = jwt_decode(response.credential);
    setUser(userObject);
    console.log(userObject.email);

    document.getElementById('SignIn').hidden = true;
    document.getElementById('SignOut').hidden = false;

    await fetch(`https://us-central1-powerbi-portal-9a6b1.cloudfunctions.net/app/QueryAccessRecord?username=${userObject.email}&timestamp=${Date.now()}`);
    // await fetch(`http://127.0.0.1:5001/powerbi-portal-9a6b1/us-central1/app/QueryAccessRecord?username=${userObject.email}&timestamp=${Date.now()}`);
  }
  
  const handleSignInError = error => {
    console.log(error);
  }
  
  const handleSignOut = event => {
    setUser();
    document.getElementById('SignIn').hidden = false;
    document.getElementById('SignOut').hidden = true;
  }

  return (
    <div id='app'>
      <span id="SignIn" >
        <GoogleLogin onSuccess={handleSignIn} onError={handleSignInError} />
      </span>

      <button id='SignOut' className="btn btn-success" hidden
          onClick={event => handleSignOut(event)}>
          Sign Out
      </button>
      {
        user
        &&
        (links.hasOwnProperty(user.email) ?
          (
            <iframe 
              id='powerbi-iframe'
              title="MEDIQ Financial Services" 
              width="100%" 
              height="99%" 
              src={ `${links[user.email]}` }
              frameborder="0" 
              allowFullScreen="true"
            >
            </iframe>
          ) : 
          (
            <h1>User account has no access permission.</h1>
          )
        )
      }
    </div>
  )
}

export default App;
