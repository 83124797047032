const links = {
  "itadmin@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=0c7470ca-56fc-4ea4-a2c7-bb74406bc870&appId=68b00ddb-cb6a-49c6-9efb-2e5da678c6fc&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780",
  "h.mai@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=32af2827-18b3-4df0-8860-4a66f8e6407d&appId=1273024d-a063-4406-b164-eb1c11f231ad&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780",
  "w.ma@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=d96f96f3-8f2d-4a0c-b600-177ef2017573&appId=574c2c4c-f718-419e-918d-c7f24bb713fd&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780",
  "n.agrawal@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=25afa0c4-fbfa-480f-8f5c-bc7847f623a8&appId=c1b9f52b-7e24-4a59-8a4b-9a8b884eef25&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780",
  "a.dogra@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=3147fd9b-00df-43c5-917b-d24f496c34f9&appId=f81ddbeb-8efc-45d0-ad9a-2e813b80dad2&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780",
  "p.pun@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=0c7470ca-56fc-4ea4-a2c7-bb74406bc870&appId=68b00ddb-cb6a-49c6-9efb-2e5da678c6fc&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780",
  "m.andrawis@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=0c7470ca-56fc-4ea4-a2c7-bb74406bc870&appId=68b00ddb-cb6a-49c6-9efb-2e5da678c6fc&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780",
  "r.agarwal@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=0c7470ca-56fc-4ea4-a2c7-bb74406bc870&appId=68b00ddb-cb6a-49c6-9efb-2e5da678c6fc&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780",
  "s.ling@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=0c7470ca-56fc-4ea4-a2c7-bb74406bc870&appId=68b00ddb-cb6a-49c6-9efb-2e5da678c6fc&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780",
  "v.setia@mediqfinancial.com.au" : 
    "https://app.powerbi.com/reportEmbed?reportId=0c7470ca-56fc-4ea4-a2c7-bb74406bc870&appId=68b00ddb-cb6a-49c6-9efb-2e5da678c6fc&autoAuth=true&ctid=39c1586a-e97e-44ea-a7eb-1e433a311780"
}
export default links;